// --------------- tabs
.nav-tabs {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 6px #00000029;
  button {
    border: 0 !important;
    font-size: 1.9rem;
    font-family: "Raleway-SemiBold";
    color: var(--code44);
    padding: 12px 30px;
  }
  .active {
    color: var(--dark-blue) !important;
    border-bottom: 3px solid var(--dark-blue) !important;
  }
}

// sticky header
.fade-in-header {
  background: #000000b3;
  padding: 8px 0 !important;
  top: -1px;
  img {
    width: 165px;
  }
  ul {
    li {
      a {
        font-size: 1.6rem !important;
      }
    }
  }
  button {
    font-size: 1.6rem !important;
  }
}
// ------- navbar ----
.navbar {
  padding: 31px 0;
  transition: 0.3s ease-in-out all;

  img {
    transition: 0.5s ease all;
  }
  .btn {
    margin-left: 2.7rem;
  }
  .theme-btn {
    padding: 9px 30px 9px !important;
    &:after {
      display: none;
    }
  }
  ul {
    align-items: center;
    li {
      padding: 0 7.9px;
      a {
        position: relative;
        letter-spacing: 0.4px;
        font-family: "Open-Sans-Medium";
        color: #ffffff !important;
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 500;
        padding: 7px 9.5px !important;
        transition: 0.5s ease-in-out all;
      }
      .active {
        &:after {
          content: "";
          height: 3px;
          background: transparent
            linear-gradient(90deg, #25c2f2 0%, #3d77ca 100%) 0% 0% no-repeat
            padding-box;
          width: 100%;
          position: absolute;
          left: -1px;
          bottom: 0;
        }
      }
    }
  }
}

// ------- slider ----------------
.main-slider {
  max-height: 928px;
  overflow: hidden;
  min-height: 600px;
  // height: 100vh;
  height: 928px;

  div[aria-roledescription="carousel"] {
    height: 100%;
    div {
      height: 100%;
      img {
        height: 100%;
        object-position: 80%;
      }
    }
  }
  .slider {
    height: 100vh;
  }
  .carousel-content {
    position: absolute;
    z-index: 999;
    top: 32rem;
    // left: 19.6rem;
    // left: 10.5%;
    max-width: 51%;
    // top: 34.5%;
    top: 320px;

    p {
      color: #ffffff;
      margin: 27px 0 48px;
      font-family: "Open-Sans-Medium";
      font-size: 2rem;
    }
    h1 {
      letter-spacing: 0px;
      font-family: "Raleway-bold";
      text-transform: uppercase;
      font-size: 8rem;
      color: #fff;
      font-weight: bolder;
      p {
        font-size: 8rem;
        margin: 0;
      }
    }
    .btn {
      padding: 12px 35px;
    }
  }
}

//  ----------- about us -------

.xeemu-about {
  .about-slider {
    img {
      min-width: 498px;
      height: 498px;
      object-fit: contain !important;
      margin: auto;
      max-width: 100%;
    }
  }
  ul {
    li {
      padding: 30px 40px 30px 0;
    }
  }
}
//NFT CSS Starts Here

.card {
  box-shadow: 0px 1px 16px #0000001a;
  border: none;
  border-radius: 0;
  .card-img,
  .card-img-top {
    border-radius: 0;
  }
}
.nft-dev {
  .card-body {
    margin-bottom: 2rem;
    h3 {
      font-family: "Raleway-SemiBold";
      font-size: 3rem;
    }
  }
}
// Metaverse CSS Starts Here

.metaverse {
  img {
    max-width: 95%;
  }
}
// Metaverse CSS Ends Here

// Our Team CSS
.our-team {
  p {
    width: 95%;
  }
}
// Our Studio CSS
.our-studio {
  p {
    width: 95%;
  }
  .col-md-4 {
    padding: 0;
  }
  img {
    max-width: 100%;
    width: 100%;
    &:hover {
      cursor: pointer;
      transform: scale(1.1) !important;
      transition: 0.5s;
    }
  }
  .gallery-img {
    margin: 0.5rem;
    overflow: hidden;
  }
  ul {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      width: 32.67%;
    }
  }
}
// gameArt
#gameArt {
  .cont-slides-img {
    padding-right: 0px;
    padding-left: 4.5rem;
  }
}
// Hiring CSS Starts Here

.hiring {
  h3 {
    font-family: "Raleway-SemiBold";
    margin-bottom: 50px;
  }
  .react-slideshow-container {
    .nav {
      &:first-child {
        left: -18px;
      }
      &:last-child {
        right: -18px;
      }
    }
  }
}

.hiring-slides {
  .card-body {
    padding: 50px 41px;
    position: relative;
    .sub-heading {
      font-family: "Raleway-SemiBold";
      font-size: 3rem;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 35px;
    }
    .btn {
      padding: 12px 30px;
    }
  }

  .card-body:before {
    content: "";
    background: transparent linear-gradient(180deg, #24c5f4 0%, #3d77ca 100%) 0%
      0% no-repeat padding-box;
    width: 5px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}
//Contact CSS Starts Here

.contact {
  form {
    padding: 5rem 5rem 4rem;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px 1px 16px #0000001a;
    margin-top: 5rem;
    margin-right: 5rem;
  }
  .addr-info-area {
    width: 100%;
    margin-top: 5rem;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
      margin-bottom: 3rem;
      li {
        margin-bottom: 1rem;
        font-size: 1.8rem;
        color: #111;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-family: "Open-Sans-Medium";
        .addr-icon {
          margin-right: 1.5rem;
          display: contents;
          img {
            max-width: 41px;
            margin-right: 10px;
          }
        }
        .addr-info {
          color: #3d77ca;
          margin-left: 1rem;
        }
      }
    }
    .location-area {
      width: 100%;
      height: 31.5rem;
      box-shadow: 0px 1px 16px #0000001a;
      iframe {
        width: 100%;
        min-height: 330px;
      }
    }
  }
}

//Footer CSS Starts here

.footer {
  padding: 0px;
  margin-top: 90px;
  background-color: #fff;
  position: relative;
  .linebar-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: transparent linear-gradient(270deg, #3d77ca 0%, #24c5f4 100%) 0%
      0% no-repeat padding-box;
  }

  .footer-social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 3rem 0;
    ul {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        margin: 0 0.3rem;
        a {
          padding: 0 0.3rem;
          &:hover {
            // transform: ;
            img {
              transition: 0.5s;
              transform: scale(0.8);
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
}
// image content card
.content-sec {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 16px #0000001a;
  padding: 32px;
  .cont-slides {
    &-img {
      padding-right: 4.5rem;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    &-cont {
      &-heading {
        h2 {
          font-family: "Raleway-SemiBold";
          letter-spacing: 0.6px;
          color: #333333;
          text-transform: uppercase;
          font-size: 3rem;
        }
        .line-bar {
          background: var(--dark-blue);
          width: 50px;
          height: 3px;
          margin-top: 10px;
        }
      }
      &-sub-small-heading {
        color: var(--code99);
        font-size: 1.8rem;
        font-family: "Open-Sans-Medium";
        padding: 15px 0;
      }
      &-btns {
        li {
          max-width: 180px;
          margin-right: 18px;
          a {
            background: transparent;
          }
        }
      }
      // &-discription {
      //   padding-top: 11px;
      // }
    }
  }
}
.react-read-more-read-less {
  font-size: 1.8rem;
  font-family: "Raleway-SemiBold";
  transition: 0.5s;
  color: var(--dark-blue);
}
.react-slideshow-container {
  .nav {
    background: transparent;
    border: 0;
    position: absolute;
    &:first-child {
      left: -58px;
    }
    &:last-child {
      right: -58px;
    }
  }
}
.card {
  @keyframes animate {
    0%,
    100% {
      opacity: 1;
    }
  }
  @keyframes animate-hover {
    0% {
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.3);
    }
    50% {
      box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.3);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
    }
  }
}
.our-team {
  .card {
    box-shadow: 0px 1px 16px #0000001a;
    position: relative;
    transition: 0.5s ease-in-out all;
    .card-body {
      padding: 0;
      .user-cont {
        padding-top: 4rem;
        padding-bottom: 2rem;
        background: #fff;
        transition: 0.5s ease-in-out all;
        position: relative;
        z-index: 99;
      }

      h2 {
        margin-bottom: 1rem;
      }
    }
    &:hover {
      cursor: pointer;
      .user-cont {
        background-color: var(--primary-color);
        transition: 0.5s ease-in-out all;
        h2,
        p {
          color: #fff;
        }
      }
      .card-text {
        opacity: 1;
        height: 32vh;
        padding: 22px;
        width: 100%;
        left: 0;
        height: 100%;
        font-size: 1.6rem;
      }
    }
    .card-text {
      position: absolute;
      background: #111111a6;
      color: #fff;
      /* opacity: 0; */
      transition: 0.5s ease-in-out all;
      height: 1px;
      top: unset;
      font-size: 0;
      width: 0;
      overflow: hidden;
      padding: 0;
      // bottom: 12.4rem;
      bottom: 0;
      left: 12rem;
    }
  }
  .react-slideshow-container {
    button {
      &:first-child {
        left: -23px;
      }
      &:last-child {
        right: -23px;
      }
    }
  }
}
.drop-row {
  [data-aos^="zoom"][data-aos^="zoom"].aos-animate {
    transform: unset !important;
  }
}

.video-custom{
  // height: 40%!important;
  video{
    height: 500px;
  }
}