img {
  max-width: 100%;
}
:root {
  --primary-color: #22a6e8;
  --dark-blue: #3d77ca;
  --code99: #999999;
  --code44: #444444;
}
#wrapper {
  overflow-x: hidden;
}
.animated.fadeIn {
  transition: 0.5s ease-in-out;
}
li {
  list-style: none;
}
.font-raleway {
  font-family: "Raleway";
}
.font-semi-bold-raleway {
  font-family: "Raleway-SemiBold";
}
.font-opensans {
  font-family: "OpenSans";
}
.font-anurati {
  font-family: Anurati;
}
.primary-color {
  color: var(--primary-color);
}
.drak-blue {
  color: var(--dark-blue);
}
.py-113 {
  padding: 113px 0 10px;
}
.pt-65 {
  padding-top: 65px;
}
.pointer {
  cursor: pointer;
}
.error-msg {
  position: relative;
  top: -9px;
  font-size: 14px;
  font-family: "Montserrat-SemiBold";
  font-weight: 500;
}
.text-success {
  font-family: "Montserrat-SemiBold";
  font-weight: 500;
}
.theme-btn {
  background: transparent linear-gradient(92deg, #24c5f4 0%, #3d77ca 50%) 0% 0%
    no-repeat padding-box;
  border-radius: 3px;
  color: #ffffff;
  font-family: "Raleway-SemiBold";
  font-size: 2rem;
  padding: 9px 30px 9px;
  border: 0;
  transition: 0.5s ease-in-out all;
  background-size: 200% auto;

  font-weight: bolder;
  &:hover {
    background-position: right center;
    color: #ffffff;
  }
  &:focus {
    border: 0;
    color: #fff;
  }
}
.common-text {
  font-family: "Open-Sans-Medium";
  font-size: 1.8rem;
  letter-spacing: 0px;
}
h3 {
  font-family: "Raleway-bold", sans-serif;
  font-size: 3rem;
  line-height: 1.3;
  position: relative;
  margin-bottom: 3rem;
  text-transform: uppercase;
  &::after {
    content: "";
    position: absolute;
    width: 5rem;
    height: 3px;
    background-color: var(--dark-blue);
    left: 0;
    // top: 5rem;
    bottom: -14px;
  }
}
.small-heading {
  font-family: "Raleway-SemiBold";
  color: #111;
  font-size: 3rem;
}
.heading {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 50px;
  .aos-animate {
    span {
      display: inline-block;
      animation: waviy 0.5s;
      animation-delay: calc(0.1s * var(--i));
    }
  }
  p {
    display: flex;
  }
  h2 {
    font-family: "Anurati";
    font-size: 4rem;
    letter-spacing: 0.8rem;
    color: #111;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .hilite {
      color: var(--dark-blue);
      margin-left: 2rem;
    }
  }
}
.heading-cont {
  margin-bottom: 3rem;
  margin-right: 141px;
}
.line-bar {
  height: 2px;
  background: transparent linear-gradient(90deg, #25c2f2 0%, #3d77ca 100%) 0% 0%
    no-repeat padding-box;
  width: 100%;
  margin-left: 2rem;
}

.sub-heading {
  color: var(--dark-blue);
  font-size: 2.2rem;
  font-family: "Montserrat-SemiBold";
}
.navbar-toggler {
  background: transparent linear-gradient(92deg, #24c5f4 0%, #3d77ca 50%) 0% 0%
    no-repeat padding-box;
  width: 56px;
  height: 36px;
  &:focus {
    box-shadow: none;
    border: 0;
    outline: none;
  }
  .navbar-toggler-icon {
    filter: invert(100%);
    padding: 15px;
  }
}
.navigationShow {
  overflow: hidden;
  transition: 0.5s ease-in-out all;
  height: 100vh;
}
// /sections
section {
  transition: 0.5s ease-in-out all;
  overflow: hidden;
}
// form
.common-form {
  .dropdown {
    .dropDown {
      border: 1px solid var(--primary-color) !important;
    }
    .dropdown-btn {
      cursor: pointer;
      color: rgba(1, 1, 1, 0.3) !important;
      span {
        img {
          position: absolute;
          right: 20px;
          transition: 0.3s ease-in-out all;
        }
      }
    }

    .dropdown-content {
      position: absolute;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      width: 100%;
      margin-top: -16px;
      padding: 0px 28px;
      z-index: 999999;
      transition: 0.5s;
    }

    .item {
      color: #b7b7b7;
      border-bottom: 1px solid #dbdbdb;
      padding: 15px 0;
      cursor: pointer;
      transition: 0.5s ease-in-out all;
      &:hover {
        color: var(--primary-color);
      }
    }
    .dropDown {
      img {
        transform: rotate(181deg);
      }
    }
  }
  form {
    input,
    select,
    .dropdown-btn {
      height: 60px;
      border: 1px solid #e3e3e3;
      border-radius: 0;
      font-size: 1.8rem;
      color: var(--code44);
      font-family: "Open-Sans", sans-serif;
      margin-bottom: 1.5rem;
      width: 100%;
      padding: 14px 20px;
      text-transform: capitalize;
      &::placeholder {
        color: rgba(1, 1, 1, 0.3);
        font-weight: normal;
        font-size: 1.8rem;
      }
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
    select {
      padding: 0 0.5rem;
    }
    textarea {
      border: 1px solid #e3e3e3;
      border-radius: 0;
      font-size: 1.8rem;
      color: var(--code44);
      font-family: "Open-Sans", sans-serif;
      width: 100%;
      resize: none;
      // height: 10rem;
      padding: 1rem;
      margin-bottom: 1.5rem;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: rgba(1, 1, 1, 0.3);
        font-weight: normal;
        font-size: 1.8rem;
      }
    }
  }
  .file-name {
    background: #3d77ca1a 0% 0% no-repeat padding-box;
    border: 1px solid #3d77ca;
    padding: 7px 31px 7px 10px;
    width: fit-content;
    position: relative;
    margin-bottom: 1.5rem;
    span {
      padding: 0 14px;
    }
    .remove-file {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
.file-selector {
  input {
    &:after {
      content: "Upload Resume";
      background: #e3e3e3;
      width: 100%;
      position: absolute;
      top: 0;
      height: 100%;
      cursor: pointer;
      border: 1px solid #70707029;
      text-align: center;
      padding: 14px 20px;
      left: 0;
    }
  }
}
// modal
.job-modal {
  .modal-dialog {
    min-width: 800px;
    .modal-content {
      padding: 40px 25px;
      border: 0;
      .modal-header {
        padding: 0 0 8px;
        align-items: flex-start;
        margin-bottom: 21px;
        border-bottom: 3px solid #3d77ca;
      }
      .modal-body {
        padding-top: 10px;
        .modal-content {
          padding: 0;
          margin-bottom: 3rem;
          &:last-child {
            margin-bottom: 0;
          }
          .modal-sub-heading {
            font-size: 2.2rem;
            font-family: "OpenSans-SemiBold";
            color: #111111;
            margin-bottom: 1.3rem;
          }
          .common-text {
            font-size: 1.6rem;
            color: #444444;
            line-height: 1.9;
            padding-bottom: 11px;
          }
          ul {
            padding-left: 0;
            span {
              float: left;
              height: 33px;
              img {
                margin-right: 10px;
              }
            }
            .common-text {
              padding-bottom: 0;
              padding-top: 5px;
            }
          }
          .skills-req {
            display: flex;
            flex-wrap: wrap;
            li {
              border: 1px solid #3d77ca;
              border-radius: 20px;
              font-family: "Open-Sans-Medium";
              padding: 5px 13px;
              margin-right: 11px;
              font-size: 1.8rem;
              margin-bottom: 10px;
              color: #111111;
            }
          }
          a {
            text-decoration: none;
            color: #3d77ca;
          }
        }
      }
      .modal-footer,
      .form-footer {
        justify-content: flex-start;
        padding-left: 0;
        padding-top: 34px;
        border-top: 3px solid #3d77ca;
        padding-bottom: 0;

        .btn {
          &:first-child {
            background: #444444;
            margin-right: 20px;
          }
        }
      }
    }
  }
}
// loader
.xeemu-loading {
  position: fixed;
  width: 100%;
  z-index: 9999;
  align-items: center;
  background: #fff;
  height: 101vh;
  overflow: hidden;
  justify-content: center;
  display: flex;
  transition: 0.5s ease-in-out all;
  img {
    height: max-content;
    width: 140px;
  }
  .loader-circle {
    position: absolute;
    left: 47.8%;
    top: 45.8%;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 5px #2387f330;
    margin-left: -60px;
    margin-top: -60px;
    display: none;
    // .animation(fade 1.2s infinite ease-in-out);
    @media screen and (max-width: 991px) {
      left: 40%;
    }
  }

  .loader-line-mask {
    position: absolute;
    display: none;
    left: 46%;
    top: 45.8%;
    width: 80px;
    height: 200px;
    margin-left: -60px;
    margin-top: -60px;
    overflow: hidden;
    transform-origin: 101px 99px;
    -webkit-mask-image: -webkit-linear-gradient(top, black, rgba(0, 0, 0, 0));
    animation: rotate 1.2s infinite linear;
    @media screen and (max-width: 991px) {
      left: 40%;
    }
    .loader-line {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      box-shadow: inset 0 0 0 1px #23d0f8;
    }
  }
}
