// -----Raleway-------
@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway-Regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "Raleway-bold";
  src: url("../fonts/Raleway-Bold.ttf");
}
@font-face {
  font-family: "Raleway-SemiBold";
  src: url("../fonts/Raleway-SemiBold.ttf");
  font-weight: normal;
}
// ----Anurati------
@font-face {
  font-family: "Anurati";
  src: url("../fonts/Anurati-Regular.otf");
  font-weight: normal;
}
// ----Open-Sans-----
@font-face {
  font-family: "Open-Sans";
  src: url("../fonts/OpenSans-Regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "Open-Sans-Medium";
  src: url("../fonts/OpenSans-Medium.ttf");
  font-weight: Medium;
}
@font-face {
  font-family: "Open-Sans-Bold";
  src: url("../fonts/OpenSans-Bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("../fonts/OpenSans-SemiBold.ttf");
  font-weight: bold;
}

// -----Montserrat------
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../fonts/Montserrat-SemiBold.ttf");
  font-weight: bold;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
body {
  font-size: 1.8rem;
  font-family: "Open-Sans", sans-serif;
  color: #444444;
  background-image: url("../images/bg-xeemu.jpg");
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  /*   box-shadow: inset 0 0 5px #22a6e8;  */
  border-radius: 10px;
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4178c885;
  border-radius: 10px;
  transition: 0.5s ease -in-out all;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #228dde;
}
