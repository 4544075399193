// waviy
.animation-waviy {
  span {
    // display: inline-block;
    // animation: waviy 1s;
    // animation-delay: calc(0.1s * var(--i));
    &:first-child {
      --i: 10;
    }
    &:nth-of-type(01) {
      --i: 12;
    }
    &:nth-of-type(02) {
      --i: 13;
    }
    &:nth-of-type(03) {
      --i: 14;
    }
    &:nth-of-type(04) {
      --i: 15;
    }
    &:nth-of-type(05) {
      --i: 16;
    }
    &:nth-of-type(06) {
      --i: 17;
    }
    &:nth-of-type(07) {
      --i: 18;
    }
    &:nth-of-type(08) {
      --i: 19;
    }
    &:nth-of-type(09) {
      --i: 10;
    }
    &:nth-of-type(10) {
      --i: 11;
    }
    &:nth-of-type(11) {
      --i: 12;
    }
    &:nth-of-type(12) {
      --i: 13;
    }
    &:nth-of-type(13) {
      --i: 14;
    }
    &:nth-of-type(14) {
      --i: 15;
    }
    &:nth-of-type(15) {
      --i: 16;
    }
    &:nth-of-type(16) {
      --i: 17;
    }
    &:nth-of-type(17) {
      --i: 18;
    }
    &:nth-of-type(18) {
      --i: 19;
    }
    &:nth-of-type(19) {
      --i: 20;
    }
    &:nth-of-type(20) {
      --i: 21;
    }
    &:nth-of-type(21) {
      --i: 22;
    }
    &:nth-of-type(22) {
      --i: 23;
    }
    &:nth-of-type(23) {
      --i: 24;
    }
    &:nth-of-type(24) {
      --i: 25;
    }
  }
}
@keyframes waviy {
  0%,
  40%,
  100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }
}
// slide overlay

// loader animation

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
