@media (min-width: 1300px) and (max-width: 1440px) {
  #wrapper {
    .container {
      max-width: 1226px;
    }
  }
}

@media (min-width: 1600px) {
  #wrapper {
    max-width: 1920px;
    margin: 0 auto;
    .container {
      max-width: 1545px;
    }
    // .main-slider .carousel-content {
    //   left: 2.5%;
    // }
  }
}

@media (max-width: 1600px) {
  .cont-slides-img .animated.fadeIn {
    height: 100%;
  }

  .navbar {
    img {
      width: 164px;
    }
    ul {
      li {
        a {
          font-size: 1.6rem;
        }
      }
    }
    .btn {
      margin-left: 1.5rem;
    }
  }
  .main-slider {
    .carousel-content {
      // top: 18rem;
      // left: 4.5%;
      top: 282px;
      max-width: 52%;

      h1 {
        font-size: 6rem;
      }
    }
  }
}
@media (max-width: 1300px) {
  .hiring-slides {
    .card {
      min-height: 443px;
      max-height: 403px;
    }
  }
}
@media (max-width: 1199px) {
  .heading {
    margin-bottom: 15px;
  }
  .heading-cont {
    margin-right: 0;
    margin-bottom: 45px !important;
  }
  .py-113 {
    padding-top: 85px !important;
  }
  .navbar {
    background: #000000b3;
    padding: 8px 0 !important;
    top: -1px;
    ul {
      align-items: flex-start;
      border-top: 1px solid #23a9e9;
      margin-top: 2rem;
      background: #0000;
      padding-top: 2rem;
      height: 100vh;
      li {
        width: 100%;
        margin: 2px 0px;
        padding: 0;
        &:last-child {
          background: transparent;
          padding-left: 0;
          padding-right: 0;
          a {
            margin-left: 0;
          }
        }
        a {
          padding: 12px 21px !important;
          background: #3397dbf0;
          border-radius: 3px;
        }
        .active {
          background-color: #2196f39c;
          &:after {
            display: none;
          }
        }
        .btn {
          text-align: left;
          padding: 12px 21px !important;
        }
      }
    }
  }
  .xeemu-about {
    .row {
      flex-direction: column-reverse;
    }
    ul {
      justify-content: center;
    }
    .about-slider {
      margin-bottom: 20px;
    }
  }
  .our-games {
    .cont-slides-cont {
      padding-top: 0px !important;
      &:last-child {
        padding-top: 20px !important ;
      }
    }
    .content-sec {
      .cont-slides-img {
        padding-right: 0;
        padding-top: 0;
      }
    }
  }
  #gameArt {
    .cont-slides-img {
      padding-top: 30px;
      padding-left: 0;
    }
  }
  .metaverse {
    .cont-sec {
      padding-top: 4rem;
    }
  }
  .hiring-slides {
    .card {
      min-height: unset;
      max-height: unset;
    }
  }
  .btn-cont {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .details {
    margin-top: 5rem;
  }
  .main-slider {
    .carousel-content {
      max-width: 100% !important;
      padding: 48px;
      background: #00000080;
      top: 246px;
      left: 8px;
      right: 8px;
    }
  }
  .contact {
    form {
      margin-right: 0;
    }
  }
}
@media (max-width: 990px) {
  #wrapper {
    .container {
      max-width: 890px;
    }
  }
  .job-modal {
    .modal-dialog {
      min-width: unset;
    }
  }
  .heading {
    display: block;
    h2 {
      width: 100%;
      flex-wrap: wrap;
      line-height: 1.5;
      text-align: center;
      align-items: center;
      justify-content: center;
      p {
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
      }
      .hilite {
        margin-left: 2rem;
      }
    }
    .line-bar {
      display: block;
      // display: none;
      margin: auto;
      width: 30%;
    }
  }
  .metaverse {
    .heading {
      h2 {
        margin-bottom: 20px;
      }
    }
  }
  .main-slider {
    .carousel-content {
      max-width: 80%;
    }
  }
  .react-slideshow-container {
    button {
      top: -22px;
      right: 0 !important;
      &:first-child {
        right: 43px !important;
        left: unset !important;
      }
    }
  }
}
@media (max-width: 767px) {
  #wrapper {
    .container {
      max-width: 600px;
      padding: 0 15px !important;
    }
  }
  .our-games {
    .content-sec {
      .cont-slides-cont-btns {
        ul {
          padding-top: 10px !important;
          li {
            max-width: 100%;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
    .react-slideshow-container {
      button {
        top: -58px;
      }
    }
  }
  p {
    font-size: 1.6rem;
    text-align: center;
  }
  h3,
  h2 {
    font-size: 2rem;
    text-align: center;
    &:after {
      left: 37%;
      width: 25%;
      position: absolute;
    }
  }
  .small-heading {
    text-align: center;
    font-size: 2.5rem;
  }
  .heading {
    h2 {
      p {
        font-size: 2.6rem;
      }
    }
  }
  .main-slider {
    height: 600px;
    .carousel-content {
      top: 120px;
      padding: 25px;
      text-align: center;
      h1 {
        font-size: 4rem;
        line-break: anywhere;
      }
      p {
        margin: 12px 0 23px;
        font-size: 1.6rem;
      }
      .btn {
        font-size: 1.6rem;
      }
    }
    .contact {
      .addr-info-area {
        ul {
          li {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
  .py-113 {
    padding-top: 50px !important;
  }
  .heading {
    margin-bottom: 35px;
  }
  .xeemu-about {
    .about-slider {
      img {
        min-width: 100%;
        height: 296px;
      }
    }
  }
  .nav-tabs {
    button {
      font-size: 1.8rem;
      padding: 13px 21px 12px;
    }
  }
  .xeemu-about {
    ul {
      flex-wrap: wrap;
      li {
        padding: 10px 25px 20px 0;
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  .content-sec {
    .cont-slides-cont-heading {
      h2 {
        font-size: 2.5rem;
      }
    }
  }
  .hiring {
    h3 {
      margin-bottom: 37px;
    }
    .hiring-slides {
      .card-body {
        h2 {
          font-size: 2.5 rem;
        }
        padding: 37px 27px;
      }
    }
  }
  .heading-cont {
    margin-bottom: 27px !important;
  }
  .contact {
    .addr-info-area {
      ul {
        li {
          display: block;
          font-size: 1.6rem;
          .addr-icon {
            margin-right: 0;
          }
        }
      }
    }
  }
  .details {
    margin-top: 4rem;
  }
  .contact {
    form {
      padding: 22px 22px;
    }
  }
  // .hiring-slides .card {
  //   min-height: 70vh;
  // }
  // .cont-slides-cont-discription {
  //   min-height: 151px;
  //   max-height: 166px;
  // }
  .content-sec {
    .cont-slides-cont-heading {
      .line-bar {
        margin-left: auto !important;
        margin-right: auto;
      }
    }
    .cont-slides-cont-sub-small-heading {
      text-align: center;
    }
  }
  .job-modal {
    .modal-dialog {
      .modal-content {
        .modal-title {
          .modal-heading {
            text-align: left;
          }
        }
        .modal-body {
          padding: 0;
          p {
            text-align: left;
          }
          .modal-content {
            .modal-sub-heading {
              font-size: 2rem;
            }
          }
        }
        .modal-footer {
          padding-top: 23px;
        }
      }
    }
  }
  .footer {
    margin-top: 32px;
    p {
      text-align: center;
      margin: 20px 0 0;
    }
    .footer-social {
      justify-content: center;
      margin: 10px 0 20px;
    }
  }
}
